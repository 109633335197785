// Gatsby supports TypeScript natively!
import React from "react"
// import Hero from "../components/templates/Hero"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Contact = () => (
  <Layout>
    <SEO title="Page two" />
    <h1>Hi from CONTACT page</h1>
  </Layout>
)

export default Contact
